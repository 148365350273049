.admin-wrap {
    padding: 20px;
}

.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 110px;
    background-color: #1a1a1a;
    color: white;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
}

.sidebar li {
    margin: 10px 0;
}

.sidebar a {
    color: white;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
}

.sidebar a:hover {
    background-color: #4d4d4d;
}

.table-c1 {
    border-right: transparent 1px solid;
    border-left: transparent 1px solid;
    padding-right: 10px;
    table-layout: fixed;
    width: 17%;
}

/* .adminTable {
    display: flex;
    position: absolute;
    left: 30%;
    width: 66%;
    height: 50%;
    top: 25%;
} */

.adminTable td {
  text-align: center;
  vertical-align: middle;
}

.adminProductTableContainer {
    max-height: 500px;
    overflow-y: auto;
    padding: 10px;
}

.snp-modal-container {
    padding: 20px;
}

.snp-search-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.snp-modal-content {
    display: flex;
    justify-content: space-between;
    max-height: 400px;
    overflow-y: auto;
}

.snp-category {
    width: 45%;
    overflow-y: auto;
}

.snp-category h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}

.snp-category ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.snp-category li {
    display: flex;
    justify-content: space-between;
    padding: 8px 10px;
    margin-bottom: 5px;
    background-color: #f8f9fa;
    border-radius: 5px;
}

.snp-rsid {
    font-weight: bold;
    color: #007bff;
}

.snp-genotype {
    font-weight: normal;
    color: #343a40;
}

/* .admin-add-user-button {

} */

.add-icon {
    color: #007bff;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s;
    position: relative;
    top: 15px;
    left: 9px;
    transform: translateY(-50%);
}

.add-icon:hover {
    color: #0056b3;
}

.MaterialFormAdmins {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: white
}

.admin-modal-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
}

.MaterialFormAdmin form {
    display: flex;
    flex-direction: column;
    margin: 6px 0 32px;
    font-size: 16px;
    padding-bottom: 26px;
    border-bottom: 1px solid var(--dark-gray-bg);
    color: var(--light-gray-text)
}

.MaterialFormAdmin form label {
    display: block;
    margin: 26px 0 15px 0;
    font-weight: 500;
}

.admin-modal-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.admin-modal-cancel-btn {
    width: 108px;
}

.search-container {
    padding-bottom: 22px;
    display: flex;
    align-items: center;
    position: relative;
    gap: 20px;
    border-bottom: 1px solid var(--dark-gray-bg);
}

.search-container-input {
    padding: 8px 20px;
    box-sizing: border-box;
    width: 330px;
    border: 1px solid #ccc;
    border-radius: 16px;
    height: 40px;
    font-size: 16px;
}

.search-container-input:focus-visible {
    /* outline: var(--accent-violet); */
    outline: none;
}

.search-container::before {
    content: '';
    width: 24px;
    height: 24px;
    background-image: url('../../assets/search-icon.svg');
    position: absolute;
    background-size: cover;
    top: 8px; 
    /* width - padding - own width */
    left: calc(330px - 20px - 24px); 
    z-index: 1;
}

/* .search-container button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 5px;
    cursor: pointer;
} */

/* .search-container button:hover {
    background-color: #0056b3;
} */

/* Pagination styles */

.pagination button {
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.pagination svg {
    cursor: pointer;
}

.pagination-numbers {
    border-bottom: 1px solid var(--dark-gray-bg);
    padding: 0 30px 10px;
    box-sizing: border-box;
    font-size: 14px;
    margin: 0px 10px;
}

.page-number {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    background-color: transparent;
    border-radius: 4px;
    color: var(--light-gray-text);
}

.page-number.active {
    color: initial;
}

.adminGeneticUploadButton {
    background-image: linear-gradient(90.44deg, #9940F6 3.9%, #6D57FC 99.63%);
    border: none;
    box-sizing: border-box;
    padding: 5px 12px;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
}

.genetic-file-button {
    width: 125px;
}

/* UserProfile.css */

.user-profile-container {
    font-family: 'Arial', sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #3498db;
    border-radius: 8px;
    background-color: #ecf0f1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.user-profile-section {
    margin-bottom: 20px;
}

.user-profile h1 {
    color: #3498db;
    text-align: center;
}

.user-profile h2 {
    color: #2c3e50;
    margin-bottom: 10px;
}

.user-profile p {
    margin: 5px 0;
    color: #555;
}

.user-profile p:hover {
    color: #3498db;
}

.admin-table {
    border-spacing: 0;
    text-align: left;
    width: 100%;
    margin-top: 22px;
}

.admin-table th, .admin-table td {
    box-sizing: border-box;
    padding: 10px;
}

.admin-table th {
    background-color: #F5F5F5;
    color: #676A95;
    font-weight: 500;
    font-size: 16px;
    padding: 20px 10px;
}

.admin-table th:first-of-type {
    border-top-left-radius: 16px;
    padding-left: 20px !important; 
} 

.admin-table tr td:last-of-type {
    padding-right: 20px !important; 
} 

.admin-table tr td:first-of-type {
    padding-left: 20px !important; 
} 

.admin-table th:last-of-type {
    border-top-right-radius: 16px;
    padding-right: 20px !important; 
} 

.admin-table tbody {
    font-size: 14px;
}

.admin-table td {
    border-bottom: 1px solid var(--dark-gray-bg)
}

.admin-table tr:hover {
    background-color: rgba(245, 245, 245, .4);
}

.admin-table-button {
    background-color: var(--light-gray-btn);
    border: none;
    box-sizing: border-box;
    padding: 5px 12px;
    border-radius: 8px;
    cursor: pointer;
    height: 27px;
    font-size: 14px;
    line-height: 1.2;
}

.admin-icons-cell {
    display: flex;
    gap: 16px;
}

.admin-icons-cell svg {
    cursor: pointer;
}

/* MYOENGINE */
.effects-config-editor {
    border: 1px solid #ccc;
    background: #fafafa;
    padding: 16px;
    border-radius: 6px;
}

.save-button {
    margin-top: 12px;
    padding: 6px 12px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.collapsible-effect {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 4px;
}

.effect-header {
    background: #f4f4f4;
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.effect-body {
    padding: 10px;
}

.form-row {
    margin-bottom: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.form-row label {
    width: 120px;
    font-weight: 600;
}

.snps-editor .snp-block {
    margin: 12px 0;
    border: 1px solid #eee;
    padding: 8px;
    border-radius: 4px;
}

.genotype-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 6px;
}

.genotype-table th,
.genotype-table td {
    border: 1px solid #ccc;
    padding: 6px;
    text-align: left;
}

.edit-input {
    border: 1px solid #007bff;
    /* Primary accent border */
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    height: 27px;
    /* Matches the height of your buttons */
    transition: box-shadow 0.2s ease-in-out;
}

.edit-input:focus {
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

@media screen and (max-width: 1240px) {
    .admin-table-wrap {
        overflow: scroll;
    }
    
    .admin-table {
        min-width: 1130px;
    }
}